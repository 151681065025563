
.center {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

nav {
    width: 100%;
    top: 0px;
    background-color: rgb(255, 255, 255);
    position: fixed;
    height: 50px;
    z-index: 9;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(143,143,143,1);
-moz-box-shadow: 0px 0px 5px 0px rgba(143,143,143,1);
box-shadow: 0px 0px 5px 0px rgba(143,143,143,1);
}

nav .logo {
    width: 46px;
    height: 46px;
    margin: 3px;
}

nav .coming-soon-title {
    position: absolute;
    top: 16px;
    right: 10px;
    font-size: 14px;
}

.main-container {
    margin-top: 50px;
    width: 100%;
    min-height: 100vh;
    padding-top: 20px;
    background: rgb(232,92,187);
    background: -moz-linear-gradient(45deg,  rgba(232,92,187,1) 0%, rgba(255,170,170,1) 100%);
    background: -webkit-linear-gradient(45deg,  rgba(232,92,187,1) 0%,rgba(255,170,170,1) 100%);
    background: linear-gradient(45deg,  rgba(232,92,187,1) 0%,rgba(255,170,170,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e85cbb', endColorstr='#ffaaaa',GradientType=1 );
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-content: center;
}

.App {
    min-height: 100vh;
    background-color: rgb(255, 255, 255);
    width: 100%;
}

.title-container {
    margin-top: 40px;
}

.title-container h2 {
    font-size: 64px;
    font-weight: bold;
    background: linear-gradient(to left, rgb(255, 255, 255), rgb(243, 243, 243));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.title-container p {
    color: white;
    font-weight: lighter;
}

.title-container ul {
    border-left: solid 1px white;
    list-style: none;
}

.title-container ul li {
    color: white;
    margin-top: 10px;
}

.main__button {
    margin-top: 20px;
    margin-bottom: 20px;
    border: solid 2px rgb(255, 255, 255);
    border-radius: 20px;
    width: 300px;
    height: 40px;
    background-color: transparent;
    color: white;
    font-weight: normal;
    font-size: 16px;
    transition: all 0.3s linear;
}

.main__button:hover {
    background-color: rgba(255, 199, 230, 0.493);
}

.data-section {
    width: 100%;
}

.curve-div {
    width: 100%;
    height: 100px;
    position: relative;
    top: -90px;
    margin-top: 100px;
}

.info-div {
    margin-top: 100px;
    margin-bottom: 40px;
    position: relative;
}

.info-div h2 {
    font-size: 44px;
    margin-bottom: 20px;
    margin-top: 20px;
    background: linear-gradient(to left, rgb(255, 170, 184), rgb(138, 102, 223));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
}

.info-div p {
    font-size: 18px;
    font-weight: lighter;
}
