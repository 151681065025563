@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap);
* {
  font-family: 'Open Sans', sans-serif !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.profile__container {
    width: calc(100% - 20px);
    max-width: 300px;
    height: 400px;
    background-color: white;
    border-radius: 20px;
    margin-top: 60px;
    margin-bottom: 20px;
    overflow: hidden;
    text-align: center;
    padding: 0px;
    
}

.profile__back {
    width: calc(100% + 4px);
    height: 104px;
    position: relative;
    top: -2px;
    left: -2px;
    background: rgb(244,88,88);
background: linear-gradient(45deg,  rgba(244,88,88,1) 0%,rgba(237,128,186,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f45858', endColorstr='#ed80ba',GradientType=1 );
}

.profile__subscribe {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 50px;
    height: 10px;
    background-color: white;
    border-radius: 5px;
}

.profile__img {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    margin: 10px;
    position: relative;
    top: -50px;
}

.profile_usericon {
    position: absolute;
    top: 158px;
    left: 10px;
    width: 15px;
    height: 15px;
    -webkit-filter: contrast(0.05);
            filter: contrast(0.05);
}

.profile__username {
    position: absolute;
    top: 154px;
    left: 30px;
    color: rgb(153, 153, 153);
    font-size: 14px;
    font-weight: lighter;
}

.profile__data  {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.profile_d1 { grid-area: 1 / 1 / 2 / 2; }
.profile_d2 { grid-area: 1 / 2 / 2 / 3; }
.profile_d3 { grid-area: 1 / 3 / 2 / 4; }

.profile_d1 h3 {
    background: linear-gradient(to left, rgb(195, 255, 195), rgb(228, 72, 207));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.profile_d2 h3 {
    background: linear-gradient(to left, #6ea3e9, rgb(223, 144, 247));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.profile_d3 h3 {
    background: linear-gradient(to left, rgb(242, 255, 124), rgb(255, 180, 129));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.profile_stat {
    text-align: center;
}

.profile_stat h3 {
    font-size: 24px;
    font-weight: bold;
}

.profile_stat p {
    font-weight: lighter;
}

.profile__subscribebutton {
    position: absolute;
    bottom: 5px;
    width: 100px;
    height: 30px;
    left: 50%;
    transform: translateX(-50%);
    color: rgb(214, 69, 130);
    -webkit-animation: pulse_button 2.0s linear infinite;
            animation: pulse_button 2.0s linear infinite;
}

@-webkit-keyframes pulse_button {
    0% {
        opacity: 0.2;
    }
    50% {
        opacity: 1.0;
    }
    100% {
        opacity: 0.2;
    }
}

@keyframes pulse_button {
    0% {
        opacity: 0.2;
    }
    50% {
        opacity: 1.0;
    }
    100% {
        opacity: 0.2;
    }
}



.center {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

nav {
    width: 100%;
    top: 0px;
    background-color: rgb(255, 255, 255);
    position: fixed;
    height: 50px;
    z-index: 9;
box-shadow: 0px 0px 5px 0px rgba(143,143,143,1);
}

nav .logo {
    width: 46px;
    height: 46px;
    margin: 3px;
}

nav .coming-soon-title {
    position: absolute;
    top: 16px;
    right: 10px;
    font-size: 14px;
}

.main-container {
    margin-top: 50px;
    width: 100%;
    min-height: 100vh;
    padding-top: 20px;
    background: rgb(232,92,187);
    background: linear-gradient(45deg,  rgba(232,92,187,1) 0%,rgba(255,170,170,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e85cbb', endColorstr='#ffaaaa',GradientType=1 );
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-content: center;
}

.App {
    min-height: 100vh;
    background-color: rgb(255, 255, 255);
    width: 100%;
}

.title-container {
    margin-top: 40px;
}

.title-container h2 {
    font-size: 64px;
    font-weight: bold;
    background: linear-gradient(to left, rgb(255, 255, 255), rgb(243, 243, 243));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.title-container p {
    color: white;
    font-weight: lighter;
}

.title-container ul {
    border-left: solid 1px white;
    list-style: none;
}

.title-container ul li {
    color: white;
    margin-top: 10px;
}

.main__button {
    margin-top: 20px;
    margin-bottom: 20px;
    border: solid 2px rgb(255, 255, 255);
    border-radius: 20px;
    width: 300px;
    height: 40px;
    background-color: transparent;
    color: white;
    font-weight: normal;
    font-size: 16px;
    transition: all 0.3s linear;
}

.main__button:hover {
    background-color: rgba(255, 199, 230, 0.493);
}

.data-section {
    width: 100%;
}

.curve-div {
    width: 100%;
    height: 100px;
    position: relative;
    top: -90px;
    margin-top: 100px;
}

.info-div {
    margin-top: 100px;
    margin-bottom: 40px;
    position: relative;
}

.info-div h2 {
    font-size: 44px;
    margin-bottom: 20px;
    margin-top: 20px;
    background: linear-gradient(to left, rgb(255, 170, 184), rgb(138, 102, 223));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
}

.info-div p {
    font-size: 18px;
    font-weight: lighter;
}

.contact__container {
    text-align: center;
    margin-top: 100px;
}

.contact__container h3 {
    font-size: 44px;
    margin-bottom: 20px;
    margin-top: 20px;
    background: linear-gradient(to left, rgb(255, 195, 195), rgb(176, 14, 197));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.contact__container p {
    font-size: 14px;
    font-weight: lighter;
    margin-bottom: 20px;
}

.contact__container form {
    margin-top: 30px;
    max-width: 400px;
    text-align: left;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}


.contact__inputcontainer {
    position: relative;
}

.contact__inputcontainer input {
    text-align: left;
    width: 100%;
    height: 40px;
    border-radius: 20px;
    border: solid 1px lightgray;
    padding: 20px;
    font-size: 18px;
    font-weight: lighter;
    background: linear-gradient(to left, rgb(255, 132, 183), #8468ff);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.contact__inputcontainer input:focus {
    outline: none;
}

.contact__inputcontainer label {
    position: absolute;
    left: 20px;
    top: -12px;
    background-color: rgb(255, 255, 255);
    padding-left: 5px;
    color: rgb(255, 117, 158);
}

.contact__inputcontainer button {
    position: absolute;
    right: 6px;
    top: 6px;
    height: 30px;
    border: none;
    background-color: rgb(250, 144, 167);
    color: white;
    border-radius: 15px;
    font-size: 14px;
    font-weight: normal;
    padding-left: 15px;
    padding-right: 15px;
    transition: all 0.3s linear;
}

.contact__messagebox {
    width: 100%;
    min-height: 100px;
    max-width: 400px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    background-color: #FF6666;
    margin-top: 20px;
    border-radius: 10px;
    overflow: hidden;
}

.contact__messagebox h4 {
    font-size: 20px;
    color: white;
    width: 100%;
    height: 40px;
    background-color: rgba(255, 164, 184, 0.541);
    text-align: center;
    padding-top: 5px;
    padding-left: 5px
}

.contact__messagebox p {
    font-weight: lighter;
    color: white;
    margin-bottom: 10px;
}

.updates__container {
    text-align: center;
    margin-top: 200px;
}

.updates__container h2 {
    font-size: 44px;
    margin-bottom: 20px;
    margin-top: 20px;
    background: linear-gradient(to left, rgb(255, 195, 195), rgb(216, 53, 53));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.updates__container p {
    font-size: 14px;
    font-weight: lighter;
    margin-bottom: 20px;
}

.updates__container h3 {
    text-align: left;
    font-size: 20px;
    font-weight: lighter;
}

.updates__container ul {
    list-style: none;
    text-align: left;
    border-left: solid rgb(230, 161, 187) 1px;
}

.updates__container ul li {
    margin-top: 20px;
    color: rgb(78, 78, 78);
    font-size: 16px;
    font-weight: lighter;
}

.updates__container ul li img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}
footer {
    margin-top: -3px;
    width: 100%;
    height: 300px;
    background-color: rgb(255, 102, 166);
    margin-top: 30px;
    background-image: url('/footerCurve.svg');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    position: relative;
}

.footer__disclaimer {
    font-size: 14px;
    font-weight: lighter;
    position: absolute;
    bottom: 10px;
    color: white;
    left: 50%;
    transform: translateX(-50%);
}
