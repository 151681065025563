.contact__container {
    text-align: center;
    margin-top: 100px;
}

.contact__container h3 {
    font-size: 44px;
    margin-bottom: 20px;
    margin-top: 20px;
    background: linear-gradient(to left, rgb(255, 195, 195), rgb(176, 14, 197));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.contact__container p {
    font-size: 14px;
    font-weight: lighter;
    margin-bottom: 20px;
}

.contact__container form {
    margin-top: 30px;
    max-width: 400px;
    text-align: left;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}


.contact__inputcontainer {
    position: relative;
}

.contact__inputcontainer input {
    text-align: left;
    width: 100%;
    height: 40px;
    border-radius: 20px;
    border: solid 1px lightgray;
    padding: 20px;
    font-size: 18px;
    font-weight: lighter;
    background: linear-gradient(to left, rgb(255, 132, 183), #8468ff);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.contact__inputcontainer input:focus {
    outline: none;
}

.contact__inputcontainer label {
    position: absolute;
    left: 20px;
    top: -12px;
    background-color: rgb(255, 255, 255);
    padding-left: 5px;
    color: rgb(255, 117, 158);
}

.contact__inputcontainer button {
    position: absolute;
    right: 6px;
    top: 6px;
    height: 30px;
    border: none;
    background-color: rgb(250, 144, 167);
    color: white;
    border-radius: 15px;
    font-size: 14px;
    font-weight: normal;
    padding-left: 15px;
    padding-right: 15px;
    transition: all 0.3s linear;
}

.contact__messagebox {
    width: 100%;
    min-height: 100px;
    max-width: 400px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    background-color: #FF6666;
    margin-top: 20px;
    border-radius: 10px;
    overflow: hidden;
}

.contact__messagebox h4 {
    font-size: 20px;
    color: white;
    width: 100%;
    height: 40px;
    background-color: rgba(255, 164, 184, 0.541);
    text-align: center;
    padding-top: 5px;
    padding-left: 5px
}

.contact__messagebox p {
    font-weight: lighter;
    color: white;
    margin-bottom: 10px;
}