footer {
    margin-top: -3px;
    width: 100%;
    height: 300px;
    background-color: rgb(255, 102, 166);
    margin-top: 30px;
    background-image: url('/footerCurve.svg');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    position: relative;
}

.footer__disclaimer {
    font-size: 14px;
    font-weight: lighter;
    position: absolute;
    bottom: 10px;
    color: white;
    left: 50%;
    transform: translateX(-50%);
}