
.profile__container {
    width: calc(100% - 20px);
    max-width: 300px;
    height: 400px;
    background-color: white;
    border-radius: 20px;
    margin-top: 60px;
    margin-bottom: 20px;
    overflow: hidden;
    text-align: center;
    padding: 0px;
    
}

.profile__back {
    width: calc(100% + 4px);
    height: 104px;
    position: relative;
    top: -2px;
    left: -2px;
    background: rgb(244,88,88);
background: -moz-linear-gradient(45deg,  rgba(244,88,88,1) 0%, rgba(237,128,186,1) 100%);
background: -webkit-linear-gradient(45deg,  rgba(244,88,88,1) 0%,rgba(237,128,186,1) 100%);
background: linear-gradient(45deg,  rgba(244,88,88,1) 0%,rgba(237,128,186,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f45858', endColorstr='#ed80ba',GradientType=1 );
}

.profile__subscribe {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 50px;
    height: 10px;
    background-color: white;
    border-radius: 5px;
}

.profile__img {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    margin: 10px;
    position: relative;
    top: -50px;
}

.profile_usericon {
    position: absolute;
    top: 158px;
    left: 10px;
    width: 15px;
    height: 15px;
    filter: contrast(0.05);
}

.profile__username {
    position: absolute;
    top: 154px;
    left: 30px;
    color: rgb(153, 153, 153);
    font-size: 14px;
    font-weight: lighter;
}

.profile__data  {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.profile_d1 { grid-area: 1 / 1 / 2 / 2; }
.profile_d2 { grid-area: 1 / 2 / 2 / 3; }
.profile_d3 { grid-area: 1 / 3 / 2 / 4; }

.profile_d1 h3 {
    background: linear-gradient(to left, rgb(195, 255, 195), rgb(228, 72, 207));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.profile_d2 h3 {
    background: linear-gradient(to left, #6ea3e9, rgb(223, 144, 247));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.profile_d3 h3 {
    background: linear-gradient(to left, rgb(242, 255, 124), rgb(255, 180, 129));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.profile_stat {
    text-align: center;
}

.profile_stat h3 {
    font-size: 24px;
    font-weight: bold;
}

.profile_stat p {
    font-weight: lighter;
}

.profile__subscribebutton {
    position: absolute;
    bottom: 5px;
    width: 100px;
    height: 30px;
    left: 50%;
    transform: translateX(-50%);
    color: rgb(214, 69, 130);
    animation: pulse_button 2.0s linear infinite;
}

@keyframes pulse_button {
    0% {
        opacity: 0.2;
    }
    50% {
        opacity: 1.0;
    }
    100% {
        opacity: 0.2;
    }
}

