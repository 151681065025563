
.updates__container {
    text-align: center;
    margin-top: 200px;
}

.updates__container h2 {
    font-size: 44px;
    margin-bottom: 20px;
    margin-top: 20px;
    background: linear-gradient(to left, rgb(255, 195, 195), rgb(216, 53, 53));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.updates__container p {
    font-size: 14px;
    font-weight: lighter;
    margin-bottom: 20px;
}

.updates__container h3 {
    text-align: left;
    font-size: 20px;
    font-weight: lighter;
}

.updates__container ul {
    list-style: none;
    text-align: left;
    border-left: solid rgb(230, 161, 187) 1px;
}

.updates__container ul li {
    margin-top: 20px;
    color: rgb(78, 78, 78);
    font-size: 16px;
    font-weight: lighter;
}

.updates__container ul li img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}